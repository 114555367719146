import './ContactForm.css';

function ContactForm() {

    return (
        <form className='ContactForm'>
            <input className='ContactForm-input' type='text' placeholder='Name' required />
            <input className='ContactForm-input' type='email' placeholder='Email' required />
            <textarea className='ContactForm-input' placeholder='Message' rows={5} required />
            <button className='ContactForm-btn' type='submit'>Submit</button>
        </form>
    );
}

export default ContactForm;