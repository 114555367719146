import './SocialIcon.css';

function SocialIcon({ className, link, color }) {
    return (
        <a className="SocialIcon" href={link} target="_blank">
            <i className={className} style={{color: color}}></i>
        </a>
    );
}

export default SocialIcon;