import './NavBar.css';
import SocialsBar from './SocialsBar';

function NavBar() {
    return (
        <header className='NavBar'>
        <nav className='NavBar-container'>
            <p className='NavBar-title'>JONATHAN DENNIS</p>
            <SocialsBar direction='row' color='teal'/>
        </nav>
        </header>
    );
}

export default NavBar;