import './Timeline.css';

function Timeline({ children }) {
    return (
        <div className='Timeline-container'>
            <ul className='Timeline'>{children}</ul>
        </div>
    );
}

export default Timeline;