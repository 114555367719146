import SocialIcon from './SocialIcon';
import './SocialsBar.css';

function SocialsBar({ direction, color }) {

    return (
        <div className="SocialsBar" style={{flexDirection: direction}}>
            <SocialIcon className="devicon-github-original" link="https://github.com/J990" color={color}/>
            <SocialIcon className="devicon-linkedin-plain" link="https://www.linkedin.com/in/jonathan-dennis-016868186/" color={color}/>
            <SocialIcon className="fab fa-discord" link="https://discordapp.com/users/298840487899561984/" color={color}/>
        </div>
    );
}

export default SocialsBar;