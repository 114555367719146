import { useEffect, useState } from 'react';
import './ProjectLinks.css';

function ProjectLinks({ github, url }) {

    const [repoLink, setRepo] = useState([]);
    const [urlLink, setUrl] = useState([]);

    function checkIfEmpty() {
        if (github) {
            setRepo(<a className='ProjectLinks-github' href={github} target='_blank'><i className="devicon-github-original ProjectLinks-icon"></i></a>)
        }
        if (url) {
            setUrl(<a className='ProjectLinks-url' href={url} target='_blank'><i className="fa-solid fa-link ProjectLinks-icon"></i></a>)
        }
    }

    useEffect(() => {
        checkIfEmpty();
    }, [])

    return (
        <div className="ProjectLinks">
            {repoLink}
            {urlLink}
        </div>
    );
}

export default ProjectLinks;