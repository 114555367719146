import { useEffect, useState } from 'react';
import './Experience.css';
import Skill from './Skill';

function Experience({ title, company, date, skills, children }) {

    const [skillComponents, setSkillComponents] = useState([]);


    function createSkills() {
        let temp = [];
        for (let i = 0; i < skills.length; i++) {
            let skill = skills[i];
            temp.push(<Skill key={i}>{skill}</Skill>)
        }
        setSkillComponents(temp);
    }

    useEffect(() => {
        createSkills();
    }, []);

    return (
        <div className='Experience'>
            <div className='Exp-content'>
                <div className='Exp-title'>
                    <h5 className='Exp-job-title'>{title}</h5>
                    <h6 className='Exp-company'>{company}</h6>
                    <p className='Exp-date'>{date}</p>
                </div>
                <p className='Exp-summary'>{children}</p>
                <div className='Exp-skills-container'>{skillComponents}</div>
            </div>
        </div>
    );
}

export default Experience;