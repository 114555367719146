import './App.css';
import './Mobile.css';
import ContactButton from './components/ContactButton';
import ContactForm from './components/ContactForm';
import Experience from './components/Experience';
import NavBar from './components/NavBar';
import ProjectLinks from './components/ProjectLinks';
import SocialsBar from './components/SocialsBar';
import Timeline from './components/Timeline';
import TimelineItem from './components/TimelineItem';
import { useEffect } from 'react';

function useResizeListener() {
  useEffect(() => {
    const projects = document.getElementsByClassName('Project');

    function updateAspectRatio() {
      for (const p of projects) {
        p.style.height = parseInt(p.clientWidth) * (9 / 16) + "px";
      }
    }

    function handleResize() {
      if (window.innerWidth <= 1080000) {
        updateAspectRatio();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
}

function App() {
  useResizeListener();

  return (
    <div className='App'>
      <div className='App-content'>
        <section id='landing-page'>
          <NavBar />
          <header className='Header'>
            <div className='Header-content'>
              <h1 className='Header-title'>Hi,</h1>
              <h1 className='Header-title'>I'm <span className='text-color'>Jonathan Dennis</span></h1>
              <h3 className='Header-subtitle'>COMPUTER SCIENCE STUDENT</h3>
              <p className='Header-para'>
              I am a passionate and determined problem solver that enjoys being creative and innovative through solving problems. 
              I am always eager to learn and develop new skills, and I want to demonstrate this in a Software Engineering role.
              </p>
            </div>
            <ContactButton />
          </header>
        </section>
        <section id='experience'>
          <h2 className='section-title'>My <span className='text-color'>Work Experience</span></h2>
          <div id='experience-timeline'>
            <Timeline>
              <TimelineItem>
                <Experience
                  title='Directory Intern'
                  company='Archdiocese of Southwark'
                  date='Summer 2022'
                  skills={["Communication", "Python", "APIs", "Problem Solving", "Microsoft Office"]}>
                    Implemented a pipeline for collecting data and updating a database. 
                    Trusted with reinforcing the integrity of sensitive data for the organisation. 
                    Automated the finding of latitudes and longitudes for over 600 locations using Python and Google Maps Platform API.
                </Experience>
              </TimelineItem>
              <TimelineItem>
                <Experience
                  title='Software Developer'
                  company='Freelance'
                  date='April 2022'
                  skills={["Project Management", "JavaScript", "Communication", "GitHub", "Testing"]}>
                    Collaborated with a client to identify the project scope and gather requirements. 
                    Developed a Discord bot that assisted the client in managing a Discord server with over 10,000 members using JavaScript. 
                    Researched and implemented web scraping to build a price tracking Discord bot which added value to the client's product. 
                    Adapted to changing user requirements and used GitHub for version control.
                </Experience>
              </TimelineItem>
              <TimelineItem>
                <Experience
                  title='Bar Associate'
                  company='JD Wetherspoon'
                  date='2020-2021'
                  skills={["Adaptability", "Emotional Intelligence", "Teamwork", "Resilience", "Customer Service"]}>
                    Responsible for serving food and drink to customers in a timely manner. 
                    Delivered excellent customer service by interacting with customers and exceeding their expectations. 
                    Adapted quickly to changing rules due to COVID-19 guidelines and communicated them to customers. 
                    Developed good working relationships with team members.
                </Experience>
              </TimelineItem>
            </Timeline>
          </div>
        </section>
        <section id='technologies'>
          <h2 className='section-title'>My <span className='text-color'>Technologies</span></h2>
          <div className='Tech'>
            <div className='Tech-container'>
              <div className='Tech-row'>
                <i className="devicon-html5-plain-wordmark Tech-icon"></i>
                <i className="devicon-react-original-wordmark Tech-icon"></i>
                <i className="devicon-css3-plain-wordmark Tech-icon"></i>
              </div>
              <div className='Tech-row'>
                <i className="devicon-java-plain-wordmark Tech-icon"></i>
                <i className="devicon-python-plain-wordmark Tech-icon"></i>
                <i className="devicon-sqlite-plain-wordmark Tech-icon"></i>
              </div>
              <div className='Tech-row'>
                <i className="devicon-javascript-plain Tech-icon"></i>
                <i className="devicon-discordjs-plain-wordmark Tech-icon"></i>
                <i className="devicon-typescript-plain Tech-icon"></i>
              </div>
            </div>
          </div>
        </section>
        <section id='projects'>
          <h2 className='section-title'>My <span className='text-color'>Projects</span></h2>
          <div className='projects-container'>
            <div className='Project'>
              <div className='Project-wrapper'>
                <div className='Project-content'>
                  <div className='Project-head'>
                    <h3 className='Project-name'>Training Plan Builder</h3>
                    <div className='Project-techs'>
                      <i className="devicon-react-original-wordmark Project-icon"></i>
                      <i className="devicon-javascript-plain Project-icon"></i>
                      <i className="devicon-sqlite-plain-wordmark Project-icon"></i>
                    </div>
                  </div>
                  <p className='Project-description'>A mobile app that provides some of the tools necessary to create training plans. Built using React Native and SQLite</p>
                  <ProjectLinks github='https://github.com/J990/GymApp' url='https://github.com/J990/GymApp' />
                </div>
              </div>
            </div>
            <div className='Project'>
              <div className='Project-wrapper'>
                <div className='Project-content'>
                  <div className='Project-head'>
                    <h3 className='Project-name'>Trading Simulator</h3>
                    <div className='Project-techs'>
                      <i className="devicon-java-plain-wordmark Project-icon"></i>
                    </div>
                  </div>
                  <p className='Project-description'>A University project, built with a GUI using Java and Swing, that simulates buying and selling stocks</p>
                  <ProjectLinks github='https://github.com/J990/GymApp' />
                </div>
              </div>
            </div>
            <div className='Project'>
              <div className='Project-wrapper'>
                <div className='Project-content'>
                  <div className='Project-head'>
                    <h3 className='Project-name'>To-Do List</h3>
                    <div className='Project-techs'>
                      <i className="devicon-javascript-plain Project-icon"></i>
                      <i className="devicon-discordjs-plain-wordmark Project-icon"></i>
                    </div>
                  </div>
                  <p className='Project-description'>A To-Do List app on Discord, built as a Discord bot using JavaScript and Discord.js</p>
                  <ProjectLinks github='https://github.com/J990/GymApp' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className='Footer'>
            <div className='Footer-form'>
              <h2 className='section-title'>Contact <span className='text-color'>Me</span></h2>
              <ContactForm />
            </div>
            <div className='Footer-socials'>
              <SocialsBar color='teal'/>
            </div>
          </div>
          <div className='Footer-bottom'>
            <a className='bottom-text bottom-left' href='mailto:jonden2003@gmail.com'>jonden2003@gmail.com</a>
            <p className='bottom-text bottom-middle'>JONATHAN DENNIS</p>
            <a className='bottom-text bottom-right' href='tel:07479524648'>+447479524648</a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;